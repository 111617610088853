import { AZ } from '@winelivery-org/wl-client-sdk-js';
import moment from 'moment';
import { useMemo } from 'react';
import useLocalizationContext from '../stores/localization/useContext';

export const useLocalization = () => {
  const defaultStoreId = process.env.NEXT_PUBLIC_DEFAULT_STORE_ID!;
  const store = useLocalizationContext((state) => state);

  const az = store.azs[0] as AZ | undefined;
  const isAsap = !!az && az.deliveryOptions.some((o) => o.value === 'asap');
  const isPreorder =
    !!az && !isAsap && az.deliveryOptions.some((o) => o.value === 'preorder');

  const asapDeliveryOption = useMemo(() => {
    if (!isAsap) {
      return;
    }
    return az.deliveryOptions.find((option) => option.value === 'asap');
  }, [az, isAsap]);

  const isOpen = useMemo(() => {
    if (!az) {
      return true;
    }
    const now = moment();
    return az.deliveryTimes
      .filter((dt) => dt.dayOfWeek === now.weekday())
      .some((slot) => {
        const after = moment(
          `${now.format('DD/MM/YYYY')} ${slot.start}`,
          'DD/MM/YYYY HH:mm:ss.SSS',
        );
        const before = moment(
          `${now.format('DD/MM/YYYY')} ${slot.end}`,
          'DD/MM/YYYY HH:mm:ss.SSS',
        );
        return now.isAfter(after) && now.isBefore(before);
      });
  }, [az]);

  const nextOpening = useMemo(() => {
    if (isOpen || !az) {
      return undefined;
    }

    let i = 0;
    const now = moment();

    let nextOpening: moment.Moment | undefined = undefined;

    while (i < 8 && !nextOpening) {
      const inc = now.clone().add(i, 'd');
      const days = az.deliveryTimes.filter(
        (d) => d.dayOfWeek === inc.weekday(),
      );

      for (const d of days) {
        const str = inc.format('DD/MM/YYYY') + ' ' + d.start;
        const tmp = moment(str, 'DD/MM/YYYY HH:mm:ss.SSS');
        if (now.isBefore(tmp)) {
          nextOpening = tmp;
          break;
        }
      }
      i++;
    }

    return nextOpening;
  }, [az]);

  const preorderDeliveryOption = useMemo(() => {
    if (!isPreorder) {
      return;
    }
    const deliveryTimes = [...az.deliveryTimes].sort((a, b) => {
      if (a.dayOfWeek < b.dayOfWeek) {
        return -1;
      }
      if (a.dayOfWeek > b.dayOfWeek) {
        return 1;
      }
      return a.start < b.start ? -1 : 1;
    });

    const now = moment();
    for (let i = 0; i < 8; i++) {
      const tf = moment(now).add(i, 'd');
      const currentSlots = deliveryTimes.filter(
        (dt) => dt.dayOfWeek === tf.weekday(),
      );
      for (const slot of currentSlots) {
        const sd = moment(slot.start, 'hh:mm:ss');
        tf.hour(sd.hour())
          .minute(sd.minute())
          .second(sd.second())
          .millisecond(sd.millisecond());
        if (now.isBefore(tf)) {
          return tf;
        }
      }
    }
    return;
  }, [az, isPreorder]);

  return {
    ...store,
    az,
    hash: az?.storesIds[0] || defaultStoreId,
    alerts: az?.alerts || [],
    deliveryType: az?.deliveryType,
    deliveryMessages: az?.deliveryMessages || [],
    isAsap,
    isExpress: az?.deliveryType === 'express',
    isEcommerce: az?.deliveryType === 'ecommerce',
    isHinterland: az?.deliveryType === 'hinterland',
    isPreorder,
    asapDeliveryOption,
    isOpen,
    nextOpening,
    preorderDeliveryOption,
    location: store.location,
  };
};
